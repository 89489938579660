<template>
       <b-input-group :class="errors ? 'border-red' : ''">
  <ValidationProvider rules="" :name="'timeHours '+type">

    <b-form-select
      v-model="timeHours"
      @change="selectedTime()"
      :options="hours"
    >
    <option :value="null" disabled>Hrs</option>
    </b-form-select>
  </ValidationProvider>
  <ValidationProvider rules="" :name="'timeMinutes '+type">
    <b-form-select
      v-model="timeMinutes"
      @change="selectedTime()"
      :options="minutes"
    >
    <option :value="null" disabled>Min</option>
    </b-form-select>
  </ValidationProvider>
    <ValidationProvider rules="" :name="'timeSeason '+type">
    <b-form-select
      v-model="timeSeason"
      @change="selectedTime()"
      :options="['AM', 'PM']"
    >
    <option :value="null" disabled>AM/PM</option>
    </b-form-select>
  </ValidationProvider>
  </b-input-group>
</template>

<script>
import { formatTime } from '@/helpers/helpers'

export default {
  created(){
    this.transformDateToDropdowns()
  },
  props: {
    value: {
      type: [String, Boolean],
      required: false
    },
    type: {
      type: String,
      required: false
    },
    errors: {
      type: Boolean,
      default: false,
      required: false
    },
    origin: {
      type:[Object,Boolean],
      default: false,
      required: false
    }
  },
  watch: {
    value(){
      this.transformDateToDropdowns()
    }
  },
  data() {
    return {
      minutes: ['00','05',10,15,20,25,30,35,40,45,50,55],
      timeUsed:this.value,
      timeHours: null,
      timeMinutes: null,
      timeSeason: null
    }
  },
  computed:{
    // minutes(){
      // const minutes = []
      // for (let min = 0; min < 60; min++) {
      //   minutes.push(min)
      // }
      // return minutes
    // },
    hours(){
      const hours = []
      for (let hrs = 1; hrs < 13; hrs++) {
        hours.push(`0${hrs}`.slice(-2))
      }
      return hours
    }
  },
	methods:{
    transformDateToDropdowns(){
      if (Boolean(this.value)) {
        const timeSplited = formatTime({time: this.value, format:"HH:mm:ss", toFormat: "hh:mm:A"}).split(':')
        this.timeHours = timeSplited[0]
        this.timeMinutes = timeSplited[1]
        this.timeSeason = timeSplited[2]
      } else this.resetTime()
    },
    selectedTime(){
      const time = `
      ${this.timeHours || '00'}:
      ${this.timeMinutes || '00'} ${this.timeSeason || 'AM'}
      `
      this.timeUsed = formatTime({time: time, format:"hh:mm A"})
      this.returnTime()
    },

    resetTime(){
      this.timeHours = null
      this.timeMinutes = null
      this.timeSeason = null
    },

    returnTime(){
      const finalTime = formatTime({time: this.timeUsed, format:"hh:mm A", toFormat: "HH:mm:ss"})
      const payload ={finalTime, type: this.type ,origin:this.origin}
      
      this.$emit('get-time',payload )
    }
  },
}
</script>

<style>
.white-background{
  background-color: white !important;
}
.maxSize{
  width: 22rem;
}
.append-time-button{
  padding: 0.5rem 0.75rem;
}
.time-cols{
  height: 10rem !important;
  padding: 0 0.5rem;
  overflow-x : auto;
}
.m-half{
  margin: 0.5rem;
}
.border-red{
  border: 1px solid #ea5455 !important;
  border-radius: 0.357rem;
}
</style>