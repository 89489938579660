<template>
  <div class="border-top">
    <b-card no-body>
      <b-tabs pills card justified v-model="tabInfoIndex" nav-class="mb-0" >
        <b-tab :title-link-class="`${!tabsId ? 'unselected-tab aditional-data-tabs' : ''}`" title="Transportación"  @click.prevent="setIndex(1)">
          <div v-show="tabsId === 1">
            <ValidationObserver ref="infoTransferForm">
              <infoTransfer :canUpdateThisBooking="canUpdateThisBooking" 
              @delete-transfer = deleteTransfer
              />
            </ValidationObserver>
          </div>
        </b-tab>
        <b-tab :title-link-class="`${!tabsId ? 'unselected-tab aditional-data-tabs' : ''}`" title="Alimentación"  @click.prevent="setIndex(2)">
          <div v-show="tabsId === 2">
            <ValidationObserver ref="infoMealsForm">
              <infoMeals :canUpdateThisBooking="canUpdateThisBooking"
              @delete-meal = deleteMeal
              />
            </ValidationObserver>
          </div>
        </b-tab>
        <b-tab :title-link-class="`${!tabsId ? 'unselected-tab aditional-data-tabs' : ''}`" title="Preferencias"  @click.prevent="setIndex(3)">
          <div v-show="tabsId === 3">
          <ValidationObserver ref="infoPreferencesForm">
            <infoPreferences :canUpdateThisBooking="canUpdateThisBooking" 
            @delete-prefer = deletePreferences
            />
          </ValidationObserver>
          </div>
        </b-tab>
        <b-tab :title-link-class="`${!tabsId ? 'unselected-tab aditional-data-tabs' : ''}`" title="Reserva restaurantes"  @click.prevent="setIndex(4)">
          <div v-show="tabsId === 4">
          <ValidationObserver ref="infoRestaurantsForm">
            <infoRestaurants :canUpdateThisBooking="canUpdateThisBooking" 
            @delete-restaurant = deleteRestaurant
            />
          </ValidationObserver>
          </div>
        </b-tab>
      </b-tabs>

    </b-card>

    <b-row class="">
      <b-col md="6">
        <b-form-group label="Notas">
          <b-form-textarea
            placeholder="Escribe la nota"
            v-model="bookingInfo.internalnotes"
            :disabled="!canUpdateThisBooking"
            rows="2"
            :maxlength="750"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Notas del propietario">
          <b-form-textarea
            rows="2"
            v-model="bookingInfo.notes"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-button class="float-right"
          v-if="canUpdateThisBooking"
          :disabled="isUpdatingManyData"
          @click.prevent="saveInfoAditional()"
          variant="primary"
        > {{isUpdatingManyData ? 'Actualizando ' : 'Actualizar'}}
          <b-spinner v-if="isUpdatingManyData" small/>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import Ripple from "vue-ripple-directive"
import { acl } from "@/modules/auth/mixins/acl"
import { showAlertMessage } from "@/helpers/helpers"
import { setFormInfoTransfer, setFormInfoMeal, setFormInfoPrefer, setFormInfoRestaurant, makeParamsOwners } from "@/helpers/fivesClubHelper"
import infoTransfer from "@/modules/fivesClub/components/catalogs/ownersBookings/infoTransfer"
import infoPreferences from "@/modules/fivesClub/components/catalogs/ownersBookings/infoPreferences"
import infoMeals from "@/modules/fivesClub/components/catalogs/ownersBookings/infoMeals"
import infoRestaurants from "@/modules/fivesClub/components/catalogs/ownersBookings/infoRestaurants"
import * as moment from "moment";


export default {
  mixins: [acl],
  directives: {
    Ripple,
  },
  async created() {
    this.resetAll()
    await this.loadInfo()
  },
  destroy(){
    this.resetAll()
  },
  components: {
    infoTransfer,
    infoPreferences,
    infoMeals,
    infoRestaurants,
  },
  props: {
    bookingInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tabInfoIndex: 0,
      isVisibleTransfer: false,
      isVisiblePreferences: false,
      isVisibleMeals: false,
      isVisibleRestaurants: false,
      isUpdatingManyData: false,
      tabsId: 1,
      originalDataTransfer: null,
      originalDataMeal: null,
      originalDataPreferences: null,
      originalDataRestaurants: null,
    };
  },

  computed: {
    ...mapState("fivesClubCatalogs", ["infoTransfer","infoMeal","infoPreferences","infoRestaurants"]),
    canUpdateThisBooking(){
      const validStatus = this.bookingInfo.status != 3 && this.bookingInfo.status != 6 && this.bookingInfo.status != 4
      return validStatus && !this.checkOutItsOver
    },
    checkOutItsOver(){
      let itsOver = false
      if(this.bookingInfo){
        const dateOut = moment(this.bookingInfo.dateout).add(23, 'hours')
        itsOver = moment().isAfter(dateOut)
      }
      return itsOver
    },
  },
  methods: {
    ...mapActions("fivesClubCatalogs", ["fetchInfoTransfer","fetchInfoMeal","fetchInfoPreferences","fetchInfoRestaurants",
                                        "saveInfoTransfer","saveInfoMeal","saveInfoPreferences","saveInfoRestaurants", "updateOwnerBooking",
                                        "updateInfoTransfer","updateInfoMeal","updateInfoPreferences","updateInfoRestaurants",
                                        "deleteInfoMeal","deleteInfoPreferences","deleteInfoTransfer","deleteInfoRestaurants"]),
    ...mapMutations("fivesClubCatalogs", ["setInfoTransfer","setInfoMeal","setInfoPreferences","setInfoRestaurants",
                                          "unsetInfoTransfer","unsetInfoMeal","unsetInfoPreferences","unsetInfoRestaurants"]),
    async loadInfo(){
      const dataTransfer = await this.fetchInfoTransfer({idBooking: this.bookingInfo.id})
      if (!!dataTransfer){
        this.setInfoTransfer(dataTransfer)
        this.originalDataTransfer = structuredClone(dataTransfer)
      }
      const dataMeal = await this.fetchInfoMeal({idBooking: this.bookingInfo.id})
      if (!!dataMeal){
        this.setInfoMeal(dataMeal)
        this.originalDataMeal = structuredClone(dataMeal)
      }
      const dataPreferences = await this.fetchInfoPreferences({idBooking: this.bookingInfo.id})
      if (!!dataPreferences){
        this.setInfoPreferences(dataPreferences)
        this.originalDataPreferences = structuredClone(dataPreferences)
      }
      const dataRestaurants = await this.fetchInfoRestaurants({idBooking: this.bookingInfo.id})
      if (!!dataRestaurants){
        this.setInfoRestaurants(dataRestaurants)
        this.originalDataRestaurants = structuredClone(dataRestaurants)
      }
    },

    setIndex(tabId){
      this.tabsId = tabId;
    },

    async saveInfoAditional() {
      this.isUpdatingManyData = true
      const {infoTransferForm, infoMealsForm, infoPreferencesForm, infoRestaurantsForm} = this.$refs

console.log({infoTransferForm, infoMealsForm, infoPreferencesForm, infoRestaurantsForm})
      if (infoTransferForm?.flags?.changed || this.originalDataTransfer?.length != this.infoTransfer?.length){
        await this.saveTransferForm(infoTransferForm)
      }

      if (infoMealsForm?.flags?.changed) await this.saveMealsForm(infoMealsForm)

      if (infoPreferencesForm?.flags?.changed) await this.savePreferencesForm(infoPreferencesForm)

      if (infoRestaurantsForm?.flags?.changed || this.originalDataRestaurants?.length != this.infoRestaurants?.length){
        await this.saveRestaurantsForm(infoRestaurantsForm)
      }

      await this.updateBooking()
      this.tabInfoIndex = 0
      this.resetAll()
      await this.loadInfo()
      this.isUpdatingManyData = false
    },

    async saveTransferForm(infoTransferForm) {
      const infoTransferHasChanged = this.detectFormValues(infoTransferForm)
      
      
      if ( infoTransferHasChanged ) {
        let payloadTransferIds = this.infoTransfer.map(data => data.id)
        const payloadTransfer = setFormInfoTransfer(this.infoTransfer, this.bookingInfo.id)
        const response = await this.saveInfoTransfer(payloadTransfer)

        if (response != false) {
          payloadTransferIds = response
          // showAlertMessage(`Registro Actualizado`, "InfoIcon", `Se ha actualizado el registro de transportación`, "success", 4000, "bottom-right")
        }
        if (!!payloadTransferIds) await this.deleteInfoTransfer({ id: payloadTransferIds, idBooking: this.bookingInfo.id })
      }
    },

    async saveMealsForm(infoMealsForm) {
      const infoMealsHasChanged = this.detectFormValues(infoMealsForm)
      if (infoMealsHasChanged) {
        const payloadMeal = setFormInfoMeal(this.infoMeal)
        payloadMeal.idBooking = this.bookingInfo.id
        if (!payloadMeal?.id) {
          const response = await this.saveInfoMeal(payloadMeal)
          // if (response != false) {
          //   showAlertMessage(`Registro Guardado`, "InfoIcon", `Se ha guardado el registro de plan de alimentación`, "success", 4000, "bottom-right")
          // }
        } else {
          const response = await this.updateInfoMeal(payloadMeal)
          // if (response != false) {
          //   showAlertMessage(`Registro Actualizado`, "InfoIcon", `Se ha actualizado el registro de plan de alimentación`, "success", 4000, "bottom-right")
          // }
        }
      }
    },

    async savePreferencesForm(infoPreferencesForm) {
      const infoPreferencesHasChanged = this.detectFormValues(infoPreferencesForm)
      if (infoPreferencesHasChanged) {
        const payloadPrefer = setFormInfoPrefer(this.infoPreferences)
        payloadPrefer.idBooking = this.bookingInfo.id
        if (!payloadPrefer?.id) {
          const response = await this.saveInfoPreferences(payloadPrefer)
          if (response != false) {
            showAlertMessage(`Registro Guardado`, "InfoIcon", `Se han guardado los registros de preferencias`, "success", 4000, "bottom-right")
          }
        } else {
          const response = await this.updateInfoPreferences(payloadPrefer)
          if (response != false) {
            showAlertMessage(`Registro Actualizado`, "InfoIcon", `Se han actualizado los registros preferencias`, "success", 4000, "bottom-right")
          }
        }
      }
    },

    async saveRestaurantsForm(infoRestaurantsForm) {
      const infoRestaurantsHasChanged=this.detectFormValues(infoRestaurantsForm)
      let payloadRestaurantIds = this.infoRestaurants.map(data => data.id)

      if(infoRestaurantsHasChanged) {
        const payloadRestaurant=setFormInfoRestaurant(this.infoRestaurants, this.bookingInfo.id)
        if(payloadRestaurant.length>0) {
          const response = await this.saveInfoRestaurants(payloadRestaurant)
          if (response != false) {
            payloadRestaurantIds = response
            // showAlertMessage(`Registro Actualizado`, "InfoIcon", `Se han actualizado las reservas de restaurantes`, "success", 4000, "bottom-right")
          }
        }
      }
      if (!!payloadRestaurantIds) await this.deleteInfoRestaurants({ id: payloadRestaurantIds, idBooking: this.bookingInfo.id })
    },

    async updateBooking(){
      const payloadNames = {
        children: this.bookingInfo.ninos,
        infoAdults: this.bookingInfo.infoAdults,
      };
      const list = makeParamsOwners(payloadNames);
			this.bookingInfo.infoBeneficiaries = list

      const { status, message } = await this.updateOwnerBooking(this.bookingInfo)
      if( status ){
        showAlertMessage(`Se ha actualizado datos de la reserva`, "InfoIcon", `Datos de reserva actualizado: ${message}`, "success", 4000, "bottom-right")
      }
    },

    async deleteMeal(mealId){
        if(!!mealId){
          const response = await this.deleteInfoMeal({ id: mealId, idBooking: this.bookingInfo.id})
          if (response != false){          
            showAlertMessage(`Registro Actualizado`, "InfoIcon", `Se ha actualizado el registro de plan de alimentos`, "success", 4000, "bottom-right")
            this.unsetInfoMeal()
            this.$refs.infoMealsForm?.form?.reset()
          }
        }
    },

    async deletePreferences(preferId){
        if(!!preferId){
          const response = await this.deleteInfoPreferences({ id: preferId, idBooking: this.bookingInfo.id})
          if (response != false){
            showAlertMessage(`Registro Actualizado`, "InfoIcon", `Se ha actualizado el registro de preferencias`, "success", 4000, "bottom-right")
            this.unsetInfoPreferences()
            this.$refs.infoPreferencesForm?.form?.reset()
          }
        }
    },
    
    async deleteTransfer(transferId){
        if(!!transferId){
          const response = await this.deleteInfoTransfer({ id: transferId, idBooking: this.bookingInfo.id, isRequiredDelete:true})
          if (response != false){
            showAlertMessage(`Registro Actualizado`, "InfoIcon", `Se ha actualizado el registro de transportación`, "success", 4000, "bottom-right")
            this.unsetInfoTransfer()
            this.$refs.infoTransferForm?.form?.reset()
            const dataTransfer = await this.fetchInfoTransfer({idBooking: this.bookingInfo.id})
            if (!!dataTransfer){
              this.setInfoTransfer(dataTransfer)
              this.originalDataTransfer = structuredClone(dataTransfer)
            }
         }
        }
    },
    
    async deleteRestaurant(restaurantId){
        if(!!restaurantId){
          const response = await this.deleteInfoRestaurants({ id: restaurantId, idBooking: this.bookingInfo.id, isRequiredDelete:true})
          if (response != false){
            showAlertMessage(`Registro Actualizado`, "InfoIcon", `Se ha actualizado el registro de reserva de restaurante`, "success", 4000, "bottom-right")
            this.unsetInfoRestaurants()
            this.$refs.infoRestaurantsForm?.form?.reset()
            const dataRestaurants = await this.fetchInfoRestaurants({idBooking: this.bookingInfo.id})
            if (!!dataRestaurants){
              this.setInfoRestaurants(dataRestaurants)
              this.originalDataRestaurants = structuredClone(dataRestaurants)
            }
          }
        }
    },       

    detectFormValues(refData){
      const hasValues = []
      const fieldsArray = Object.keys(refData.refs)
      fieldsArray.forEach(field => {
        if (refData.refs[field].value) hasValues.push(field)
      });
      return hasValues.length > 0
    },

    resetAll(){
      const {infoTransferForm, infoMealsForm, infoPreferencesForm, infoRestaurantsForm} = this.$refs

      this.tabsId = 1
      this.unsetInfoTransfer()
      this.unsetInfoMeal()
      this.unsetInfoPreferences()
      this.unsetInfoRestaurants()

      infoTransferForm?.form?.reset()
      infoMealsForm?.form?.reset()
      infoPreferencesForm?.form?.reset()
      infoRestaurantsForm?.form?.reset()
    }
  },
}

</script>
<style>

.aditional-data-tabs:hover{
  background-color: #5e587323 !important;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out, background 0.25s, border 0.25s;
}
.aditional-data-tabs:hover{
  background-color: #5e587323 !important;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out, background 0.25s, border 0.25s;
}
</style>