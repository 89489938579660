<template>
  <div class="row">
    <hr>

    <b-col>
      <h6 :class="alignText">Motivo de autorización:</h6>
      <h4 :class="alignText">{{detail.dataAuthorization[0].reasonAuthorization}}</h4>
    </b-col>
    <b-table
      :items="detail.dataAuthorization"
      :fields="fields"
      show-empty
      empty-text="Sin registros encontrados"
      class="position-relative mb-0 "
    />
    <!-- <table class="table mb-0">
      <thead>
        <tr>
          <th scope="col"><strong>Fecha de autorización:</strong></th>
          <th scope="col"><strong>Autorizado por:</strong></th>
          <th scope="col"><strong>Notas:</strong></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row"><p>{{detail.dateAuthorization || 'Sin autorizar'}}</p></th>
          <th scope="row"><p>{{detail.authorizationBy || 'No disponible'}}</p></th>
          <th scope="row"><p>{{detail.noteAuthorization || 'Sin notas'}}</p></th>
        </tr>
      </tbody>
    </table> -->

  </div>

  <!-- <div v-else>
    <hr>
    <h4 class="text-center">Esta reserva no se ha autorizado</h4>
  </div> -->
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      required: true,
    },
    alignText: {
      type: String,
      required: true
    }
  },
  data(){
    return{
      fields: [
        { key: "dateAuthorization", label: "Fecha de autorización:", class: "text-center", formatter: value => {
          return value || 'Sin autorizar'
        } },
        { key: "authorizationBy", label: "Autorizado por:", formatter: value => {
          return value || 'No disponible'
        }},
        { key: "noteAuthorization", label: "Notas", formatter: value => {
          return value || 'Sin notas'
        } },
      ],
    }
  }
}
</script>