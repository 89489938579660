<template>
    <div>
        <b-row class="border rounded mb-2 pt-1">
                <b-col md="12"  v-if="infoPreferences.id">
                    <b-button
                        @click="deleteForm(infoPreferences.id)"
                        :disabled="!canUpdateThisBooking"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        size="sm"
                    > <feather-icon icon="TrashIcon" style="color: white" />
                    </b-button>
                </b-col>
                <b-col md="12 mt-2">
                    <ValidationProvider rules="" name="Caja de propietario">
                        <b-form-group label="Caja de propietario:">
                            <b-form-input
                                v-model="infoPreferences.ownerbox"
                                :disabled="!canUpdateThisBooking"
                                type="text"
                                placeholder="Ingrese dato de caja de propietario"
                                maxlength="20"
                            >
                            </b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="12">
                    <ValidationProvider rules="" name="Requerimientos especiales para la habitación">
                        <b-form-group label="Requerimientos especiales para la habitación:">
                            <b-form-input
                                v-model="infoPreferences.requirementsroom"
                                :disabled="!canUpdateThisBooking"
                                type="text"
                                placeholder="Ingrese requerimiento especial"
                                maxlength="100"
                            >
                            </b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="12">
                    <ValidationProvider rules="" name="Celebraciones especiales">
                        <b-form-group label="Celebraciones especiales:">
                            <b-form-input
                                v-model="infoPreferences.specialcelebrations"
                                :disabled="!canUpdateThisBooking"
                                type="text"
                                placeholder="Ingrese celebración especial"
                                maxlength="50"
                            >
                            </b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="12">
                    <ValidationProvider rules="" name="Actividades adicionales">
                        <b-form-group label="Actividades adicionales:">
                            <b-form-input
                                v-model="infoPreferences.additionalactivities"
                                :disabled="!canUpdateThisBooking"
                                type="text"
                                placeholder="Ingrese actividad adicional"
                                maxlength="50"
                            >
                            </b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="12">
                    <ValidationProvider rules="" name="Habitaciones Adicionales">
                        <b-form-group label="Habitaciones Adicionales:">
                            <b-form-input
                                v-model="infoPreferences.extrarooms"
                                :disabled="!canUpdateThisBooking"
                                type="text"
                                placeholder="Ingrese si existen habitaciones adicionales"
                                maxlength="20"
                            >
                            </b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
        </b-row>
    </div>
 </template>
 <script>
 import { mapState } from "vuex"
 import Ripple from "vue-ripple-directive"
 import { acl } from "@/modules/auth/mixins/acl"
 import { utils } from '@/modules/fivesClub/mixins/utils'

 export default {
    mixins: [acl, utils],
    directives: {
        Ripple,
    },
    props: {
        canUpdateThisBooking: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            
        };
    },
    computed: {
        ...mapState("fivesClubCatalogs", ["infoPreferences"]),
    },
    methods: {
        deleteForm(){
            this.$emit('delete-prefer', this.infoPreferences.id)
        },        
    },
}

</script>