<template>
  <b-card-text>
    <b-row class="mb-2">
      <b-col cols="12" md="6">
        <ValidationProvider name="Hoteles" rules="required">
          <b-form-group
            slot-scope="{ valid, errors }"
            label="Seleccione hotel*"
          >
            <b-form-select
              :state="errors[0] ? false : valid ? true : null"
              v-model="idHotel"
            >
              <option value="">Seleccione hotel</option>
              <option v-for="hotel in hotels" :key="hotel.id" :value="hotel.id">
                {{ hotel.name }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Numero de cuarto*">
          <b-form-input
            type="search"
            placeholder="Buscar Vivienda o Propietario"
            v-model="numberHouse"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="12 text-right" md="12">
        <b-button :disabled="idHotel=='' || numberHouse==''" variant="success" @click="sendHousing()">
          <b-icon icon="search"></b-icon> Buscar
        </b-button>
      </b-col>

      <b-col v-if="!isLoadinsHousingOwners" cols="12 mt-2" md="12">
        <h4 class="text-center" v-if="owners">{{ owners.fullname }}</h4>
        <table v-if="owners" class="table table-sm table-striped">
          <thead>
            <tr>
              <th scope="col">Correo</th>
              <th scope="col">Nombre</th>
              <th scope="col">telefono</th>
              <th scope="col">Usar</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in owners.owner"
              :key="index"
            >
              <td scope="row">{{ item.ownerEmail }}</td>
              <td scope="row">{{ item.ownerName + " " + item.ownerLastname }}</td>
              <td scope="row">{{ item.ownerPhone }}</td>
              <td scope="row">
                <b-button variant="primary" class="btn-add" @click="sendInfo(item.idOwner)">
                  <feather-icon icon="PlusIcon" size="16" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col v-else>
        <div class="isCentered isSpinner">
          <center>
            <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
            Cargando reservas de propietarios
          </center>
        </div>
      </b-col>
      <b-alert class="mt-2" variant="danger" v-if="validHousing" show>
        <div class="alert-body text-center">
          <span>
            <strong>¡Sin registros!</strong> No se encontraron propietarios registrados.</span>
        </div>
      </b-alert>
    </b-row>
  </b-card-text>
</template>



<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  async mounted() {
    this.setOwners('null')
  },
  props:{
    showBookingsFilters: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      numberHouse: "",
      housingid:"",
      idHotel: "",
      validHousing: false,
      mqShallShowLeftSidebar: false,
      isLoadinsHousingOwners: false,
      datenow: new Date()
    };
  },
  computed: {
    ...mapState("shop", ["benefits", "owners"]),
    ...mapState("start", ["hotels"]),
  },
  methods: {
    ...mapActions("fivesClubCatalogs", ["getHousingOwners",]),
    ...mapMutations("shop", ["setContract", "setOwners"]),
    ...mapMutations("fivesClubCatalogs", ["setBookingInfo","clearDataSearchForm"]),
    async sendInfo(item) {
       this.isLoadinsHousingOwners = true
        const payload = {
        numberHouse: this.housingid,
        id:item,
        year: this.datenow.getFullYear()
      };  
          
      this.$emit("set-owners",payload);
      
      this.isLoadinsHousingOwners = false
    },

    async sendHousing() {
      this.isLoadinsHousingOwners = true
      this.$emit("disable-booking")
      this.$emit("disable-rooms")
      this.clearDataSearchForm()
      this.setContract("");
     
      const payload = {
        numberHouse: this.numberHouse,
        idHotel: this.idHotel,
      };

      const { data:ownersInfo } = await this.getHousingOwners(payload);
      this.housingid=ownersInfo.housingid
      this.setOwner(ownersInfo);
      if (!this.owners.length) this.validHousing = true
      else this.validHousing = false

      this.isLoadinsHousingOwners = false
    },
  },
};
</script>





