<template>
    <b-row class="p-1">
        <b-col cols="12" class="m-0">
            <b-button
                v-if="canUpdateThisBooking"
                @click="addFormRes"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="float-right"
            ><feather-icon icon="PlusIcon" style="color: white" />
            </b-button>
        </b-col>

        <b-col class="border rounded mb-1 mt-1">
            <b-form>
                <b-row class="border-bottom pt-1" v-for="(arrayRestaurant, index) in isRestaurantAll" :key="arrayRestaurant.key">
                    <b-col md="3">
                            <ValidationProvider rules="required" :name="'Fecha'">
                                <b-form-group slot-scope="{ valid, errors }" label="Fecha">
                                <flat-pickr
                                    :disabled="!canUpdateThisBooking"
                                    v-model="arrayRestaurant.date"
                                    class="form-control"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                />
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
 
                    <b-col md="3">
                        
                        <ValidationProvider rules="" :name="'Restaurante '+index">
                            <b-form-group label="Restaurante:">
                                <b-form-input
                                    v-model="arrayRestaurant.restaurant"
                                    :disabled="!canUpdateThisBooking"
                                    type="text"
                                    placeholder="Ingrese el restaurante"
                                    maxlength="20"
                                ></b-form-input>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
               
                            <b-col md="3">
                    <ValidationObserver :ref="'restauranteList'+index+'Form'"> 
                        <b-form-group label="Horario:">
                            <TimePickerDropdown
                                v-model="arrayRestaurant.schedule"
                                :disabled="!canUpdateThisBooking"
                                :origin="arrayRestaurant"
                                :type="'schedule'+index"
                                placeholder="Ingrese el horario"
                                @get-time="setTime"
                            
                            />
                        </b-form-group>
                    </ValidationObserver>
                    </b-col>
                    <b-col md="">
                        <ValidationProvider rules="" :name="'Confirmación '+index">
                            <b-form-group label="Confirmación:">
                                <b-form-input
                                    v-model="arrayRestaurant.confirmation"
                                    :disabled="!canUpdateThisBooking"
                                    type="text"
                                    placeholder="Ingrese la confirmación"
                                    @keypress="onlyNumbersLetters"
                                    maxlength="20"
                                ></b-form-input>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="1" class="custom-margin-delete">
                        <b-button 
                            @click="handledelete(arrayRestaurant)"
                            :disabled="!canUpdateThisBooking"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="float-right"
                        ><feather-icon icon="TrashIcon" style="color: white" />
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-col>
    </b-row>
</template>
<script>
import { mapState, mapMutations } from "vuex"
import Ripple from "vue-ripple-directive"
import { acl } from "@/modules/auth/mixins/acl"
import { stringAleatorio,sweetAlert } from "@/helpers/helpers"
import { utils } from '@/modules/fivesClub/mixins/utils'
import flatPickr from 'vue-flatpickr-component'
import TimePickerDropdown from '@/modules/fivesClub/components/catalogs/ownersBookings/components/TimePickerDropdown'
export default {
	mixins: [acl, utils],
	directives: {
		Ripple,
	},
    props: {
        canUpdateThisBooking: {
            type: Boolean,
            required: true,
        },
    },  
   
	data() {
        return {
            test:null

    };
  },
  computed: {
    ...mapState("fivesClubCatalogs", ["infoRestaurants"]),

    isRestaurantAll: {
        get(){
            return this.infoRestaurants
        },
        set(isRestaurantAll) {
            this.setInfoRestaurants(isRestaurantAll)
        }
    } ,
  },
  components: {
    TimePickerDropdown,
    flatPickr
  },
  methods: {
    ...mapMutations("fivesClubCatalogs", ["setInfoRestaurants"]),

    addFormRes(){
        this.infoRestaurants.push({
            id: '',
            key: stringAleatorio(),
            date: '',
            restaurant: '',
            schedule: '',
            confirmation: '',
        })
    },
    async handledelete(item){
        const swalert = {
        title: 'Eliminar',
        message: '¿Desea eliminar registro?',
       
        confirmButton: 'Eliminar',
        cancelButton: 'Cancelar',
        }
        if(item.id){
            const {isConfirmed} = await sweetAlert(swalert)
            if (isConfirmed) {
                this.$emit('delete-restaurant', item.id)

                
            }
          
            
        }else{
            this.isRestaurantAll = this.isRestaurantAll.filter(data => data.id ? data.id !== item.id : data.key !== item.key)
        }
    },    
    


    
    setTime(payload){
       const idRestaurant= payload.origin
       let restaurantItem= this.isRestaurantAll.find(  restaurant => restaurant.id ? restaurant.id == idRestaurant.id : restaurant.key == idRestaurant.key )
       restaurantItem.schedule=payload.finalTime
    },

  },
}

</script>

<style scoped>
.custom-margin-delete{
    margin-top: 2rem !important;
}
</style>