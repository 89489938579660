<template>
  <b-modal size="sm" :id="'modal-add' + detail.idTransaction" :title="'Autorizando beneficio: '+detail.benefitname" ok-only hide-footer >
    <validation-observer ref="addConsumption" tag="form" v-slot="{ invalid }">
      <b-form @submit.prevent="saveConsumption(consumption)"> 
        <b-row class="mb-2">
          <b-col md="6">
            <ValidationProvider rules="required" name="cantidad">
              <b-form-group label="Cantidad" slot-scope="{ valid, errors }">
                <b-form-input
                  class="form-control"
                  type="number"
                  step="any"
                  placeholder="Agrege cantidad"
                  v-model="consumption.quantity"
                  @input="correctBalance(consumption.quantity)"
                  :disabled="detail.benefitqty == 0"
                  :state="errors[0] ? false : valid ? true : null"
                >
                </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12">
            <ValidationProvider rules="required" name="Fecha">

              <b-form-group label="Fecha de Operación" slot-scope="{ valid, errors }">
                <b-form-input
                  type="date"
                  :min="selectedOwnerBookingEdit.checkIn || selectedOwnerBookingEdit.datein "
                  :max="selectedOwnerBookingEdit.checkOut || selectedOwnerBookingEdit.dateout "
                  :state="errors[0] ? false : valid ? true : null"
                  v-model="consumption.date"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

          </b-col>
          <b-col md="12">
            <ValidationProvider rules="required" name="Notas">
              <b-form-group label="Notas del consumo">
                <b-form-textarea
                  v-model="consumption.note"
                  placeholder="Escribe la nota"
                  :maxlength="100"
                  rows="2"
                >
                </b-form-textarea>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="12">
            <div class="text-right">
              <b-button :disabled="invalid || !validbalance" variant="primary" type="submit" v-if="!isSaving">Guardar</b-button>
              <div v-if="isSaving">
                Guardando..<b-spinner  variant="primary" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { utils } from '@/modules/fivesClub/mixins/utils'
import { heightFade } from '@core/directives/animations'
import { showAlertMessage, toDecimal } from '@/helpers/helpers' 

export default {
  async mounted() {
    this.consumption.date = this.selectedOwnerBookingEdit.checkIn || this.selectedOwnerBookingEdit.datein;
  },
  mixins: [utils], 
  directives: {
    heightFade
  },
  props: {
    detail: {
      type: Object,
      required: true,
    },
      selectedOwnerBookingEdit: {
      type: Object,
      required: true,
    },
    idContract: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      consumption:{
        quantity: 0,
        note: '',
        date: '',
      },
      datenow: new Date(),
      validbalance: true
    }
  },
  computed: {
    ...mapState('auth', ['user']),
   
  },
  methods: {
    ...mapActions('fivesClubContracts', ['saveDetailTransactions', 'fetchTransactionsDetail','fetchTransactions']),

    async saveConsumption(consumption) {
      this.isSaving = true
      const { quantity, note, date } = consumption
      const payload = { id: this.detail.idTransaction, quantity:parseFloat(quantity), idUser: this.user.idUser, note, date, ownerbookingId: this.selectedOwnerBookingEdit.id, type: 1 }
      const transSaved = await this.saveDetailTransactions(payload)
      if (transSaved.status) showAlertMessage('Beneficio Autorizado', 'InfoIcon', 'El beneficio ha sido autorizado', 'success', 4000, 'bottom-right' )
      const info = {
        id: this.idContract,
        year: this.datenow.getFullYear(),
      };
      const infoDetail = {
        id: this.detail.idTransaction,
      };
      const transactions = await this.fetchTransactions(info)
      const transactionsDetails = await this.fetchTransactionsDetail(infoDetail)
      this.isSaving = false
       this.$emit("receive-reload") 
      this.$root.$emit("bv::hide::modal", "modal-add" + this.detail.idTransaction)
      this.$emit("receive-transactions", transactionsDetails, transactions)
      this.$emit("delete-detail", data)
      this.consumption = {
        quantity: 0,
        note: '',
        date: '',
      }
      this.$emit("refresh-table")
    },
    correctBalance(value){
      const number = Number(toDecimal(value))
      const balance = Number(this.detail.balance)
      if (number > balance){
        showAlertMessage('Error', 'InfoIcon', 'El valor debe ser menor al balance', 'danger', 4000, 'bottom-right' )
        this.validbalance = false
        this.$root.$emit("bv::hide::modal", "modal-add" + this.detail.idTransaction)
        this.consumption.quantity = 0
      } else if (number < 0){
        showAlertMessage('Error', 'InfoIcon', 'El valor no debe ser negativo', 'danger', 4000, 'bottom-right' )
        this.validbalance = false
        this.$root.$emit("bv::hide::modal", "modal-add" + this.detail.idTransaction)
        this.consumption.quantity = 0
      } else this.validbalance = true
    }
  },
};
</script>


