<template>
  <b-table
      :items="documents"
      :fields="tableColumns"
      show-empty
      responsive
      empty-text="No se tiene archivos para este reserva"
      class="position-relative mb-0"
    >
        <template #cell(fileRoute)="row">
            <span class="text-nowrap">
            {{ row.item.fileRoute.split('/').pop()}}
            </span>
        </template>
        <template #cell(createData)="row">
            <div v-if="row.item.createUser || row.item.createDate">
                <span class="text-nowrap" v-if="row.item.createUser">
                    Creado por <br> <strong>{{ row.item.createUser }}</strong>
                </span> <br>
                <span class="text-nowrap" v-if="row.item.createDate">
                    Creado el <br> <strong>{{ row.item.createDate }}</strong>
                </span>
            </div>
            <div v-else>
                N/A
            </div>
        </template>
        <template #cell(actions)="row">
            <span class="text-nowrap">
                <a class="btn btn-sm btn-primary m-half" :href="imgUrl + row.item.fileRoute" target="_blank">
                    <feather-icon icon="DownloadIcon" size="16" />
                </a>
            </span>
            <b-button
                v-if="activeBooking"
                v-b-tooltip.hover
                title="Borrar documento"
                class="btn btn-sm btn-danger ml-1"
                @click="deleteFile(row.item)"         
                v-can="'fivesclub_owner_booking_show_booking_reservation_list_action_button_delete_file'"
              >
                <feather-icon icon="TrashIcon" size="16" />
            </b-button>
        </template>
    </b-table>
</template>

<script>
export default {
    props: {
        documents:{
            type: Array,
            required: true,
        },
        idBooking:{
            type: Number,
            default: 0,
        },
        activeBooking:{
            type: Boolean
        }
        
    },
    data() {
        return {
            imgUrl: process.env.VUE_APP_IMG_SRC_API,
            isSavingFile: false,
            tableColumns: [
                { key: 'name', label: 'Nombre',},
                { key: 'description', label: 'Descripción' },
                { key: 'typename', label: 'Tipo' },
                { key: 'fileRoute', label: 'Archivo'},
                { key: 'createData', label: 'Creación'},
                { key: 'actions' },
            ],
        }
    },
   
    methods:{
        async deleteFile(file){
             const isConfirmed = await this.confirmDeletFile() 
             if(isConfirmed){
                this.$emit('delete-file', file)                
             }
        },
        async confirmDeletFile(){
            const { isConfirmed } = await this.$swal.fire({
                icon: 'warning',
                title: `Borrar documento`, 
                text: `¿Estás seguro de borrar este documento?`,
                showDenyButton: true,
                confirmButtonText: `Sí, seguro`,
                denyButtonText: `Cancelar`,
                customClass: {                
                    confirmButton: 'mr-1',        
                }
            })
            return isConfirmed
        }
    }
}
</script>

<style scoped>
.m-half{
    margin: 0.5rem 1rem
}
</style>