<template>
  <b-modal
    size="sm"
    :id="'modal-delete' + detail.id"
    :title="detail.benefit + ' | ' + detail.ownerName"
    ok-only
    hide-footer
  >
    <b-card-text>
      <b-row class="mb-2">
        <b-col md="12">
          <b-form-group label="Nota de cancelacion">
            <b-form-textarea
              v-model="detail.notesCancel"
              placeholder="Escribe la nota de cancelacion"
              :maxlength="100"
              rows="2"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <div class="text-right">
            <b-button
              :disabled="invalid"
              variant="primary"
              @click="cancelDetail(detail)"
            >
              <b-spinner small v-if="isSaving" />Confirmar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-text>
  </b-modal>
</template>

<script>
import * as moment from "moment";
import { mapActions, mapMutations } from "vuex";

export default {
  async mounted() {
    this.invalid = false;
  },
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      invalid: false,
      isSaving: false,
      note: "",
    };
  },
  computed: {},
  methods: {
    async cancelDetail(item) {
      this.$emit("cancelDetail", item);
      this.$emit("receive-reload") 
      this.$root.$emit("bv::hide::modal", "modal-delete" + this.detail.id);
    },
  },
};
</script>


