<template>
  <b-modal 
    size="xl"
    :id="'modal-transaction-detail' + detail.idTransaction"
    :title="'Autorizando beneficio: '+detail.benefitname"
    ok-only
    scrollable
    hide-footer
  >
    <div v-if="transactionsDetail.length != 0">
        <h5>Detalle de transacciones</h5>

        <b-table
        responsive
        small
        :items="transactionsDetail"
        :fields="fieldsDetail"
        :tbody-tr-class="rowClassCancel"
        class="mb-2"
        >
          <!-- Optional default data cell scoped slot -->
          <template #cell(createdateFormart)="data">
            {{ formatDateInfo(data.item.createdate, "es") }}
          </template>
          <template #cell(actions)="row">
            <b-button
              size="sm"
              variant="danger"
              v-if="row.item.statusTransactions != 'Cancelado' && row.item.orderdetail==null && activeBooking"
              @click="deleteInfo(row.item)"
              class="mr-2"
            >
              <!-- v-can="'fivesclub_contracts_show_contracts_action_button_transactions_delete'" -->
              <feather-icon icon="TrashIcon" size="16" />
            </b-button>
            <ModalCancelDetail
              :detail="row.item"
              @cancelDetail="setDeleteDetail"
            />
          </template>
        </b-table>
    </div>
    <b-col v-if="loadingInfoDetail" class="col-md-12 text-center">
        <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
        </div>
    </b-col>
    <div v-if="transactionsDetail.length == 0 && setDetailClass != ''">
        <b-alert show variant="danger">
        <div class="alert-body text-center">
            <span>Esta orden no tiene detalle transacciones realizadas</span>
        </div>
        </b-alert>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { utils } from '@/modules/fivesClub/mixins/utils'
import { heightFade } from '@core/directives/animations'
import { showAlertMessage, formatDateOnly } from '@/helpers/helpers' 
import ModalCancelDetail from "@/modules/fivesClub/components/catalogs/ownersBookings/ModalCancelDetail"

export default {
  mixins: [utils], 
  directives: {
    heightFade
  },
  props: {
    detail: {
      type: Object,
      required: true,
    },
    idContract: {
      type: Number,
      required: true,
    },
    transactionsDetail: {
      type: Array,
      required: true,
    },
    loadingInfoDetail: {
      type: Boolean,
      required: true,
    },
    setDetailClass: {
      type: Number,
      required: false,
    },
    activeBooking: {
      type: Boolean,
    }
  },
  components: {
    ModalCancelDetail,
  },
  data() {
    return {
      isSaving: false,
      consumption:{
        quantity: 0,
        amount: 0,
        note: '',
        date: '',
      },
      fieldsDetail: [
        // { key: "benefit", label: "Beneficio", class: "text-center" },
        { key: "invoice", label: "Orden" },
        { key: "quantity", label: "Cantidad" },
        // { key: "amount", label: "Monto" },
        { key: "userName", label: "Creado por" },
        { key: "statusTransactions", label: "estatus" },
        { key: "createdateFormart", label: "Fecha de creacion" },
        { key: "operationdate", label: "Fecha de operación" }, 
        { key: "notes", label: "Notas" },
        { key: "userCancelName", label: "Cancelado por" },
        { key: "cancelNotes", label: "Notas de Cancelación" },
        { key: "actions", label: "Acciones" },
        
      ],
      datenow: new Date(),
      validbalance: true
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('fivesClubContracts', ['saveDetailTransactions', 'fetchTransactionsDetail','fetchTransactions']),
    formatDateInfo(fecha, locale) {
      return formatDateOnly(fecha, locale);
    },

    async saveConsumption(consumption) {
      this.isSaving = true
      const { amount, quantity, note, date } = consumption
      const payload = { id: this.detail.idTransaction, amount:parseFloat(amount), quantity:parseFloat(quantity), idUser: this.user.idUser, note, date, type: 1}
      const transSaved = await this.saveDetailTransactions(payload)
      if (transSaved.status) showAlertMessage('Beneficio Autorizado', 'InfoIcon', 'El beneficio ha sido autorizado', 'success', 4000, 'bottom-right' )
      const info = {
        id: this.idContract,
        year: this.datenow.getFullYear(),
      };
      const infoDetail = {
        id: this.detail.idTransaction,
      };
      const transactions = await this.fetchTransactions(info)
      const transactionsDetails = await this.fetchTransactionsDetail(infoDetail)
      this.isSaving = false
      this.$root.$emit("bv::hide::modal", "modal-add" + this.detail.idTransaction)
      this.$emit("receive-transactions", transactionsDetails, transactions)
      this.consumption = ""
    },
    correctBalance(value){
      if (value > this.detail.balance){
        value = 0
        showAlertMessage('Error', 'InfoIcon', 'El valor debe ser menor al balance', 'danger', 4000, 'bottom-right' )
        this.validbalance = false
        this.$root.$emit("bv::hide::modal", "modal-add" + this.detail.id)
      } else this.validbalance = true
    },
    rowClassCancel(item, type){
      this.$emit("row-class-cancel", item, type)
    },
    setDeleteDetail(data){
      this.$emit("delete-detail", data)
    },
    deleteInfo(data) {
      this.$root.$emit("bv::show::modal", "modal-delete" + data.id);
    }
  },
};
</script>
