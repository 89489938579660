<template>
    <b-form @submit.prevent="">
        <div>
            <b-row class="border rounded mb-2 pt-1">
                    <b-col md="12" v-if="infoMeal.id">
                        <b-button
                            @click="deleteMeal(infoMeal.id)"
                            :disabled="!canUpdateThisBooking"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="float-right"
                            size="sm"
                        > <feather-icon icon="TrashIcon" style="color: white" />
                        </b-button>
                    </b-col>
                    <b-col md="12 mt-1">
                        <ValidationProvider rules="" name="Plan de Alimentos">
                            <b-form-group label="Plan de Alimentos:">
                                <b-form-input
                                    v-model="infoMeal.mealplan"
                                    :disabled="!canUpdateThisBooking"
                                    type="text"
                                    placeholder="Ingrese plan de alimentos"
                                    maxlength="20"
                                >
                                </b-form-input>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="12">
                        <ValidationProvider rules="" name="Alergias">
                            <b-form-group label="Alergias:">
                                <b-form-input
                                    v-model="infoMeal.allergies"
                                    :disabled="!canUpdateThisBooking"
                                    type="text"
                                    placeholder="Ingrese alergias"
                                    maxlength="50"
                                >
                                </b-form-input>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="12">
                        <ValidationProvider rules="" name="Alimentación Específica">
                            <b-form-group label="Alimentación Especifica:">
                                <b-form-input
                                    v-model="infoMeal.specialMeal"
                                    :disabled="!canUpdateThisBooking"
                                    type="text"
                                    placeholder="Ingrese alguna alimentación específica"
                                    maxlength="50"
                                >
                                </b-form-input>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>                

            </b-row>
        </div>
    </b-form>
</template>

<script>
import { mapState } from "vuex"
import Ripple from "vue-ripple-directive"
import { acl } from "@/modules/auth/mixins/acl"
import { utils } from '@/modules/fivesClub/mixins/utils'

export default {
    mixins: [acl, utils],
    directives: {
        Ripple,
    },
    props: {
        canUpdateThisBooking: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            
        };
    },
    computed: {
        ...mapState("fivesClubCatalogs", ["infoMeal"]),
    },
    methods: {  
        deleteMeal(){
            this.$emit('delete-meal', this.infoMeal.id)
        },
    },
}

</script>