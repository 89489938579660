<template>
  <b-modal
    size="lg"
    :id="'modal-add-document' + bookingInfo.contractid"
    title="Añadir un documento"
    ok-only
    scrollable
    hide-footer
  >
    <b-overlay :show="isSavingFile" rounded class="p-2">
        <ValidationObserver ref="uploadFilesForm" v-slot="{ invalid }">
            <b-form @submit.prevent="saveFile">
                <b-row>                   
                    <b-col md="6">
                        <ValidationProvider name="tipo de archivo" rules="required">
                            <b-form-group label="Tipo de archivo" slot-scope="{ valid, errors }">
                                <b-form-select :state="errors[0] ? false : valid ? true : null" v-model="docFile.type" >
                                    <option value="">Seleccione tipo</option>
                                    <option v-for="file in fileTypes" :key="file.id" :value="file.id" > {{ file.type }} </option>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
               
                    <b-col md="6">
                        <ValidationProvider rules="required" name="archivo">
                            <b-form-group label="Archivo" slot-scope="{ valid, errors }" >                                
                            <b-form-file
                                v-model="docFile.file"
                                placeholder="Adjunte un archivo..."
                                drop-placeholder="Drop file here..."
                                :state="errors[0] ? false : valid ? true : null"
                                browse-text="🔎"
                                @input="setImgFile(docFile.file)"    
                            />
                                <b-form-invalid-feedback> {{ errors[0] ? "Se debe adjuntar un archivo" : "" }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                            <b-card-text class="my-1" v-if="docFile.file">
                                Archivo seleccionada: <strong>{{ docFile ? docFile.file.name : "" }}</strong>
                            </b-card-text>
                    </b-col>                    
                </b-row>
                <b-row>
                    <b-col>
                        <div class="float-right">
                            <b-button
                            type="submit"
                            class="btn-block"
                            variant="primary"
                            :disabled="invalid || isSavingFile"
                            >
                            <b-spinner small v-if="isSavingFile" /> Subir archivo
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapActions, mapState  } from 'vuex'
import { showAlertMessage } from '@/helpers/helpers'

export default {
    props: {
        fileTypes:{
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            isSavingFile: false,
            docFile: {
                name:'',
                type:'',                
                description:'',
                file: null,
            }
        }
    },
    computed: {
        ...mapState('fivesClubCatalogs', ['bookingInfo']),
        ...mapState('auth', ['user']),
    }, 
    methods: {
        ...mapActions('fivesClubContracts', ['saveFileIbooking','getFilesByBooking']),
        setImgFile(file){            
            if(file){
                // remuevo la extension del archivo
                this.docFile.name = file.name.replace(/\.[^\/.]+$/, '')
                this.docFile.description = file.name.replace(/\.[^\/.]+$/, '')
            }
            if(!file){
                this.docFile.name =''
                this.docFile.description =''
            }
        },
        async saveFile(){
            this.isSavingFile = true
            const { name, type, description, file } = this.docFile
            const id = this.bookingInfo.id
            const payload = { name, type, description, file, idBooking: id, idUser: this.user.idUser }
                                 
            const { status, message } = await this.saveFileIbooking(payload) // saving in backend

            if (status) {
                showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
                Object.assign(this.$data, this.$options.data())
                this.$refs.uploadFilesForm.reset() //reset form                
                const documents = await this.getFilesByBooking({ idBooking:id }) // vuelvo a pedir la info del back        
                this.$emit('set-documents', documents)
            } else showAlertMessage('Fallo al subir archivo', 'InfoIcon', 'No se ha podido subir el archivo, intentelo más tarde', 'danger', 4000, 'bottom-right' )
            this.isSavingFile = false
        }
    }
}
</script>