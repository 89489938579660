var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"sm","id":'modal-add' + _vm.detail.idTransaction,"title":'Autorizando beneficio: '+_vm.detail.benefitname,"ok-only":"","hide-footer":""}},[_c('validation-observer',{ref:"addConsumption",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveConsumption(_vm.consumption)}}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"cantidad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Cantidad"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","step":"any","placeholder":"Agrege cantidad","disabled":_vm.detail.benefitqty == 0,"state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.correctBalance(_vm.consumption.quantity)}},model:{value:(_vm.consumption.quantity),callback:function ($$v) {_vm.$set(_vm.consumption, "quantity", $$v)},expression:"consumption.quantity"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Fecha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Fecha de Operación"}},[_c('b-form-input',{attrs:{"type":"date","min":_vm.selectedOwnerBookingEdit.checkIn || _vm.selectedOwnerBookingEdit.datein,"max":_vm.selectedOwnerBookingEdit.checkOut || _vm.selectedOwnerBookingEdit.dateout,"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.consumption.date),callback:function ($$v) {_vm.$set(_vm.consumption, "date", $$v)},expression:"consumption.date"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Notas"}},[_c('b-form-group',{attrs:{"label":"Notas del consumo"}},[_c('b-form-textarea',{attrs:{"placeholder":"Escribe la nota","maxlength":100,"rows":"2"},model:{value:(_vm.consumption.note),callback:function ($$v) {_vm.$set(_vm.consumption, "note", $$v)},expression:"consumption.note"}})],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"text-right"},[(!_vm.isSaving)?_c('b-button',{attrs:{"disabled":invalid || !_vm.validbalance,"variant":"primary","type":"submit"}},[_vm._v("Guardar")]):_vm._e(),(_vm.isSaving)?_c('div',[_vm._v(" Guardando.."),_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e()],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }