<template>
    <b-row class="p-1">
        <b-col cols="12" class="mb-1 pr-0">
            <b-button
                v-if="canUpdateThisBooking"
                @click="addForm"
                variant="primary"
                class="float-right">
                <feather-icon icon="PlusIcon" style="color: white" />
            </b-button>
        </b-col>
        <b-col>
            <b-form @submit.prevent="">
                <b-row class="border rounded mb-2 pt-1" v-for="(arrayTransfer, index) in isTranferAll" :key="arrayTransfer.key">
                    <b-col md="12"  >
                        <b-button
                            @click="handledelete(arrayTransfer)"
                            :disabled="!canUpdateThisBooking"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="float-right"
                            size="sm"
                        > <feather-icon icon="TrashIcon" style="color: white" />
                        </b-button>
                    </b-col>

                    <b-col md="6">
                        <h5><strong>LLEGADA</strong></h5>
                        <b-row class="border-right">
                            <b-col md="12">
                            <b-row class="border-right">
                            <b-col md="4">
                                <ValidationProvider rules="" :name="'Número de POS '+index">
                                    <b-form-group label="Número de POS:">
                                        <b-form-input
                                            v-model="arrayTransfer.arrivalorder"
                                            :disabled="!canUpdateThisBooking"
                                            type="text"
                                            placeholder="Ingrese el número de orden"
                                            maxlength="20"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            </b-row>
                        </b-col>
                            <b-col md="6">
                                <ValidationProvider rules="" :name="'Aerolinea '+index">
                                    <b-form-group label="Aerolínea:">
                                        <b-form-input
                                            v-model="arrayTransfer.arrivalairline"
                                            :disabled="!canUpdateThisBooking"
                                            type="text"
                                            placeholder="Ingrese la aerolínea de llegada"
                                            maxlength="20"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                           
                            <b-col md="6">
                                <ValidationProvider rules="" :name="'Número de vuelo '+index">
                                    <b-form-group label="Número de vuelo:">
                                        <b-form-input
                                            v-model="arrayTransfer.arrivalflight"
                                            :disabled="!canUpdateThisBooking"
                                            type="text"
                                            placeholder="Ingrese el número de vuelo de llegada"
                                            @keypress="onlyNumbersLetters"
                                            maxlength="20"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
      
      
                            <b-col md="6">
                            <ValidationProvider rules="required" :name="'Fecha de Llegada '+index">
                                <b-form-group slot-scope="{ valid, errors }" label="Fecha de Llegada">
                                <flat-pickr
                                    :disabled="!canUpdateThisBooking"
                                    v-model="arrayTransfer.arrivaldate"
                                    class="form-control"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                />
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                       
                            <b-col md="6">
                                <ValidationObserver :ref="'arrivaltimeList'+index+'Form'"> 
                                <b-form-group  label=" Hora de Llegada:">
                                    <TimePickerDropdown
                                        v-model="arrayTransfer.arrivaltime"
                                        :type="'arrivaltime'+index"
                                        :origin="arrayTransfer"
                                        :disabled="!canUpdateThisBooking"
                                        @get-time="setTime"
                                    />
                                </b-form-group>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col md="6" >
                        <h5><strong>SALIDA</strong></h5>
                        <b-row>
                            <b-col md="12">
                            <b-row class="border-right">
                            <b-col md="4">
                                <ValidationProvider rules="" :name="'Número de POS '+index">
                                    <b-form-group label="Número de POS:">
                                        <b-form-input
                                            v-model="arrayTransfer.departureorder"
                                            :disabled="!canUpdateThisBooking"
                                            type="text"
                                            placeholder="Ingrese el número de orden"
                                            maxlength="20"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            </b-row>
                        </b-col>
      
                            <b-col md="6">
                                <ValidationProvider rules="" :name="'Aerolínea '+index">
                                    <b-form-group label="Aerolínea:">
                                        <b-form-input
                                            v-model="arrayTransfer.departureairline"
                                            :disabled="!canUpdateThisBooking"
                                            type="text"
                                            placeholder="Ingrese la aerolínea de salida"
                                            maxlength="30"
                                        ></b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider rules="" :name="'Número de vuelo '+index">
                                    <b-form-group label="Número de vuelo:">
                                        <b-form-input
                                            v-model="arrayTransfer.departureflight"
                                            :disabled="!canUpdateThisBooking"
                                            type="text"
                                            placeholder="Ingrese el número de vuelo de salida"
                                            @keypress="onlyNumbersLetters"
                                            maxlength="20"
                                        ></b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
              
              
                            <b-col md="6">
                            <ValidationProvider rules="required" :name="'Fecha de Salida '+index">
                                <b-form-group slot-scope="{ valid, errors }" label="Fecha de Salida">
                                <flat-pickr
                                    :disabled="!canUpdateThisBooking"
                                    v-model="arrayTransfer.departuredate"
                                    class="form-control"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                />
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                         
                            <b-col md="6">
                                <ValidationObserver :ref="'departuretimeList'+index+'Form'"> 
                                <b-form-group  label=" Hora de Salida:">
                                    <TimePickerDropdown
                                        v-model="arrayTransfer.departuretime"
                                        :type="'departuretime'+index"
                                        :origin="arrayTransfer"
                                        :disabled="!canUpdateThisBooking"
                                        @get-time="setTime"
                                    />
                                </b-form-group>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col>
                        <b-row class="border-top pt-1 ">
                            
                            <b-col md="3">
                                <b-form-group label="Documenta equipaje:" >
                                    <b-form-select
                                    v-model="arrayTransfer.baggage"
                                    :disabled="!canUpdateThisBooking"
                                    >
                                    <option value="">Seleccione una opcion</option>
                                    <option
                                        v-for="answersList in answers"
                                        :key="answersList.name"
                                        :value="answersList.name"
                                    > {{ answersList.name }}
                                    </option>
                                    </b-form-select>
                                </b-form-group>
                    
                            </b-col>
                            <b-col md="3">
                            <b-form-group label="Silla para bebé:" >
                                <b-form-select
                                v-model="arrayTransfer.babychair"
                                :disabled="!canUpdateThisBooking"
                                >
                                <option value="">Seleccione una opcion</option>
                                <option
                                    v-for="answersList in answers"
                                    :key="answersList.name"
                                    :value="answersList.name"
                                > {{ answersList.name }}
                                </option>
                                </b-form-select>
                            </b-form-group>
                
                           </b-col>
                          
                        </b-row>
                    </b-col>
                </b-row>
            </b-form>
        </b-col>
    </b-row>
 </template>

<script>
import { mapState,  mapMutations} from "vuex"
import Ripple from "vue-ripple-directive"
import { acl } from "@/modules/auth/mixins/acl"
import { stringAleatorio,sweetAlert ,} from "@/helpers/helpers"
import { utils } from '@/modules/fivesClub/mixins/utils'
import TimePickerDropdown from '@/modules/fivesClub/components/catalogs/ownersBookings/components/TimePickerDropdown'
import flatPickr from 'vue-flatpickr-component'
import { answers } from '@/data/data'

export default { 
	mixins: [acl, utils],
	directives: {
		Ripple,
	},
    props: {
        canUpdateThisBooking: {
            type: Boolean,
            required: true,
        },
    },
	data() {
        return {
            answers
    };
  },
  components: {
    TimePickerDropdown,
    flatPickr
  },
  computed: {
    ...mapState("fivesClubCatalogs", ["infoTransfer"]),
    configRangeDate() {
		let config = { mode: "range" }
		if (this.myContracts) {
			const { dateStart, dateEnd } = this.myContracts.contractSelected
			const fechaActual = currentDate() //fechaActual
			const currentDatePlusOnYear = moment().add(1, "years").format("YYYY-MM-DD") //a la fechaActual se le suma un año
			//si fecha fin de contrato es mayor que un año la fecha maxima para reservar es hasta un año, sino hasta el fecha fin de contrato
			config.maxDate = this.endContractIsGreaterThanOneYear ? currentDatePlusOnYear : dateEnd
			config.minDate = moment(dateStart).isAfter(fechaActual) ? dateStart : fechaActual
		}
		return config
		},
        
    isTranferAll: {
        get(){
            return this.infoTransfer
        },
        set(isTranferAll) {
            this.setInfoTransfer(isTranferAll)
        }
    } ,
  },
  methods: {
    ...mapMutations("fivesClubCatalogs", ["setInfoTransfer"]),

    addForm(){
        this.infoTransfer.push({
            id: '',
            key: stringAleatorio(),
            arrivalairline: '',
            arrivalflight: '',
            arrivaldate: '',
            arrivaltime: '',
            departureairline: '',
            departureflight: '',
            departuredate: '',
            departuretime: '',
            baggage: '',
            babychair: '',
            arrivalorder: '',
            departureorder: '',
        })
    },

    async handledelete(item){
       
      const swalert = {
        title: 'Eliminar',
        message: '¿Desea eliminar el registro?',
       
        confirmButton: 'Eliminar',
        cancelButton: 'Cancelar',
      }
      if(item.id){    
        const {isConfirmed} = await sweetAlert(swalert)
            if (isConfirmed) {
                    this.$emit('delete-transfer', item.id)
                
            }

            
        }else{
            this.isTranferAll = this.isTranferAll.filter(data => data.id ? data.id !== item.id : data.key !== item.key)
        }
     
      
    },


    setTime(payload){
       const idArrayTransfer= payload.origin
       let arrayTransferItem= this.isTranferAll.find(  arrayTransfer => arrayTransfer.id ? arrayTransfer.id == idArrayTransfer.id : arrayTransfer.key == idArrayTransfer.key )
       if (payload.type.includes( 'arrivaltime')) arrayTransferItem.arrivaltime = payload.finalTime
       if (payload.type.includes('departuretime')) arrayTransferItem.departuretime = payload.finalTime

    },
  },
}

</script>