<template>
	<b-card>
		<b-card-body>
			<div class="head-book mt-1">
				<h3 v-if="ownersBookingData.fullname">{{ownersBookingData.fullname + "| " + ownersBookingData.guestname || ""}}</h3>
				<b-button size="sm" variant="warning" @click="goToHome">
					<feather-icon icon="ArrowLeftIcon" size="16" /> Regresar
				</b-button>
			</div>
			<div class="mt-3 mb-2" v-if="!!ownersBookingData">
				<b-badge variant="warning mr-1">
					<feather-icon icon="AlertCircleIcon" class="mr-25" />
					<span> Origen de la Reserva: {{ ownersBookinType }} </span>
				</b-badge>

				<b-badge variant="primary mr-1">
					<feather-icon icon="AlertCircleIcon" class="mr-25" />
					<span> Código de Confirmación: {{  ownersBookingData.reservanumber ? ownersBookingData.reservanumber : '..' }} </span>
				</b-badge>

				<b-badge variant="primary mr-1" v-if="ownersBookingData.benefitsUsed.length" >
					<feather-icon icon="AlertCircleIcon" class="mr-25" />
					<span> {{ ownersBookingData.benefitsUsed.length === 1 ? 'Beneficio' : 'Beneficios' }}: {{ stringBenefits(ownersBookingData.benefitsUsed )  }} </span>
				</b-badge>

			
				<div class="mt-1">
					
					<b-badge variant="primary mr-1" v-if="bookingInfo.benefits && bookingInfo.contractList.membershipcode != 'CLASSIC' && !isValidYearContract">
						<feather-icon icon="AlertCircleIcon" class="mr-25" />
						<span> Vigencia del contrato: {{ bookingInfo.contractList.dateStart }} - {{ bookingInfo.contractList.dateEnd }} </span>
					</b-badge>

					<b-badge variant="warning mr-1" v-if="bookingInfo.benefits && bookingInfo.contractList.membershipcode != 'CLASSIC' && isValidYearContract">
						<feather-icon icon="AlertCircleIcon" class="mr-25" />
						<span> Su contrato vence este año {{ bookingInfo.contractList.dateEnd }} </span>
					</b-badge>
			

					<b-badge variant="primary mr-1">
						<feather-icon icon="AlertCircleIcon" class="mr-25" />
						<span> Membresía: {{ actualcontract ? actualcontract.membershipcode : "" }} </span>
					</b-badge>

					<b-badge variant="primary mr-1">
						<feather-icon icon="AlertCircleIcon" class="mr-25" />
						<span> Categoría: {{ ownersBookingData.roomBooking || "" }} </span>
					</b-badge>

				</div>


				
			</div>

			<b-row> <!-- Razones por las cuales no se puede actualizar la reserva -->
				<!-- <b-col cols="12" class="mb-1">
					<h3 class="text-center">Reserva {{ ownersBookingData.code || '...' }} </h3>
				</b-col> -->
				<br>
				<b-col cols="12" v-if="ownersBookingData.status == 3 || ownersBookingData.status == 6">
					<b-alert variant="danger" show>
						<div class="alert-body text-center">
							<span>
								<strong>{{ownersBookingData.status == 3 ? '¡Reserva cancelada!' : ownersBookingData.status == 6 ? 'Reserva en espera de cancelación' : ''}}</strong><br />
								Notas: {{ownersBookingData.status == 3 ? ownersBookingData.cancelNotes : ownersBookingData.status == 6 ? (ownersBookingData.cancelRequestNotes || ownersBookingData.cancelNotes || '') : ''}}
							</span>
						</div>
					</b-alert>
				</b-col>

				<b-col cols="12" v-else-if="checkOutItsOver">
					<b-alert show variant="danger" class="" v-if="checkOutItsOver">
						<div class="alert-body text-center" v-if="checkOutItsOver"> La reserva no se puede actualizar, sus fechas ya pasaron: {{bookingInfo ? `${bookingInfo.datein} / ${bookingInfo.dateout}` : ''}}</div>
					</b-alert>
				</b-col>

				<b-col cols="12" v-else-if="!bookingEditable.valid">
					<b-alert show variant="danger" class="">
						<div class="alert-body text-center">
							<span>La reserva no se puede actualizar, </span>
							<span v-if="bookingEditable.before">la ocupación es para mañana.</span>
							<span v-else-if="bookingEditable.current">¡los huéspedes están en casa! 😮</span>
							<span v-else-if="checkInItsOver && !bookingEditable.current">Reserva pasada.</span>
						</div>
					</b-alert>
				</b-col>

				<b-col cols="12" v-else-if="ownersBookingData.status == 4">
					<b-alert variant="warning" show>
						<div class="alert-body text-center">
							<span>
								<strong>Reserva en espera de autorización.</strong><br />
								Motivo:
								<span v-for="auths in ownersBookingData.dataAuthorization" :key="auths.reasonAuthorization"> {{ auths.reasonAuthorization }} <br></span>
							</span>
						</div>
					</b-alert>
				</b-col>
			</b-row>

			<b-tabs class="mt-1" content-class="mt-2" fill active-nav-item-class="font-weight-bold">
				<b-tab title="Información General">

					<div v-if="isLoadingBenefits" class="isCentered isSpinner mt-2 text-center">
						<b-spinner class="isCentered" label="Spinning"></b-spinner> <br />
						Cargando información...
					</div>

					<b-alert show variant="danger" v-if="noValidContract" >
						<div class="alert-body text-center">
							<feather-icon class="mr-25" icon="InfoIcon"/>
							<span class="ml-25">No puede realizar reservas con el contrato actual.</span>
						</div>
					</b-alert>

					<SearchForm
						:roomsData="ownersBookingData"
						:isLoadingRooms="isLoadingRooms"
						:isLoadingBenefits="isLoadingBenefits"
						:isLoadingTableBenefit="isLoadingTableBenefit"
						:showBookingsFilters="showBookingsFilters"
						:listBenefit="listBenefit"
						:itemFields="itemFields"
						:fileTypes="fileTypes"
						:documents="documents"
						:validBenefit="validBenefit"
						:dateContractEnd="dateContractEnd"
						:checkOutItsOver="checkOutItsOver"
						:checkInItsOver="checkInItsOver"
						:bookingEditable="bookingEditable"
						@set-owners="sendInfo"
						@search-rooms="searchRooms"
						@load-info="loadInfo"
						@reload="reload"
						@set-files="setDocuments"
						@set-benefitsyear="searchBenefitsByYear"
					/>

					<RoomsList
						v-if="ownersBookingData.status != 3"
						:isLoadingRooms="isLoadingRooms"
						:showRooms="showRooms"
						:listBenefit="listBenefit"
						:validationsData="validationsData"
						@get-initial-data="loadInfo"
					/>
				</b-tab>
				<b-tab title="Información Adicional">
					<div v-if="isLoadingBenefits" class="isCentered isSpinner mt-2 text-center">
						<b-spinner class="isCentered" label="Spinning"></b-spinner> <br />
						Cargando información...
					</div>
					<infoAditional
						v-else
						:validationsData="validationsData"
						:bookingInfo="ownersBookingData"
						@get-initial-data="loadInfo"
					/>
				</b-tab>
				<b-tab title="Beneficios">
					<BookingBenefits
						:bookingInfo="bookingInfo"
						:isLoadingTableBenefit="isLoadingTableBenefit"
						:yearEdit="yearEdit"
						:authURL="authURL"
						:itemFields="itemFields"
						@reload="reload"
					/>
				</b-tab>
				<b-tab title="Documentos">
					<div class="container mt-3">
						<ListFiles
							:documents="documents"
							:idBooking="bookingInfo.id"
							:activeBooking="bookingInfo.status != 3"
							@delete-file="deleteDocument"
							v-if="!isLoadingFiles"
						/>
						<div class="btn-docs-up">

							<b-button
								variant="primary"
								v-b-tooltip.hover
								title="Añadir documento"
								@click="showModalDocuments"
								class="btn btn-primary"
								v-if="can('fivesclub_owner_booking_show_booking_reservation_list_action_button_add_file')"
							>
								<feather-icon icon="UploadIcon" size="16" />
							</b-button>
						</div>
						<UploadFiles @set-documents="setDocuments" :fileTypes="fileTypes" />
					</div>
				</b-tab>
				<b-tab title="Autorización" v-if="!!ownersBookingData.dataAuthorization">
					<ModalAuthBooking :detail="ownersBookingData" alignText="text-center" />
				</b-tab>
			</b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapActions,  mapMutations, mapState } from "vuex"
import Ripple from "vue-ripple-directive"
import { searchBookingData } from "@/data/data"
import FilterOwner from "@/modules/fivesClub/components/catalogs/ownersBookings/FilterOwner"
import SearchForm from "@/modules/fivesClub/components/catalogs/ownersBookings/SearchForm"
import HandlerBenefits from "@/modules/fivesClub/components/catalogs/ownersBookings/HandlerBenefits"
import BookingBenefits from "@/modules/fivesClub/components/catalogs/ownersBookings/BookingBenefits"

import RoomsList from "@/modules/fivesClub/components/catalogs/ownersBookings/RoomsList"
import ModalAuthBooking from "@/modules/fivesClub/components/catalogs/ownersBookings/ModalAuthBooking"
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ListFiles from "@/modules/fivesClub/components/catalogs/ownersBookings/docs/ListFiles";
import { acl } from "@/modules/auth/mixins/acl"
import UploadFiles from "@/modules/fivesClub/components/catalogs/ownersBookings/docs/UploadFilesOwner";
import * as moment from "moment";
import infoAditional from "@/modules/fivesClub/components/catalogs/ownersBookings/infoAditional"

import { toJson, showAlertMessage ,formatDateOnly, currentDate} from "@/helpers/helpers"
import {
  payloadBookigOwnerDingus,
  findSelectedBenefitsforOwnersNigths,
  makeParamsOwners,
} from "@/helpers/fivesClubHelper"

import flatPickr from "vue-flatpickr-component"

export default {
	mixins: [acl],
	directives: {
		Ripple,
	},
	async created() {
		await this.loadInfo()
	},
	components: {
		AppCollapse,
		AppCollapseItem,
		flatPickr,
		ModalAuthBooking,
		SearchForm,
		RoomsList,
		FilterOwner,
		ListFiles,
		UploadFiles,
		BookingBenefits,
    	HandlerBenefits,
		infoAditional
	},
	data() {
		return {
			booking: "",
			owner: "",
			resortId: "",
			tabIndex: 0,
			idBooking: "",
			dateContractEnd:"",
			isLoadingBenefits: false,
			validBenefit: false,
			isLoadingTableBenefit:false,
			isLoadingRooms: false,
			showBookingsFilters: false,
			showRooms: false,
			itemFields: [],
			transactionsData: [],
			fileTypes: [],
			documents: [],
			actualcontract: null,
			noValidContract: false,
			authURL: process.env.VUE_APP_IMG_SRC_API,
			isLoadingFiles: false,
			validationsData: {
				needAuthorization: false,
				messageAuth: '',
				hasCutoff: false,
				messageCutoff: ''
			},

		}
	},
	computed: {
		...mapState("shop", ["benefits", "owners"]),
		...mapState("start", ["hotels"]),
		...mapState("auth", ["user"]),
		...mapState("fivesClubCatalogs", ["bookingInfo"]),
		...mapState("auth", ["user"]),
		yearEdit(){
			return new Date().getFullYear()
		},
		isValidYearContract(){
			let isValidContract = false
			const dateContract= new Date( this.bookingInfo.contractList.dateEnd )
			const yearContract = dateContract.getFullYear()
			const currentYear = new Date().getFullYear()
			isValidContract = ( yearContract == currentYear)
			return isValidContract
		},
		listBenefit() {
			let valid
			if (this.bookingInfo?.benefits) {
				const benefits = this.bookingInfo?.benefits?.filter((benefit) => benefit.code !== "TA")
				valid = benefits && benefits?.length != 0 ? 1 : 0
			} else valid = 0
			return valid
		},
		ownersBookingData() {
			let info = this.bookingInfo
			if (this.booking == null) info = searchBookingData
			return info
		},
		ownersBookinType() {
			return this.bookingInfo.origin == '2' ? "SalesCore" : this.bookingInfo.originname
		},
		yearEdit(){
			const currentYear = new Date().getFullYear()
			return this.bookingInfo ? new Date(this.bookingInfo.datein ).getFullYear() : currentYear
		},
		checkOutItsOver(){
			let itsOver = false
			if(this.bookingInfo){
			const dateOut = moment(this.bookingInfo.dateout).add(23, 'hours')
			itsOver = moment().isAfter(dateOut)
			}
			return itsOver
		},
		checkInItsOver(){
			let itsOver = false
			if(this.bookingInfo) itsOver = moment().isAfter(this.bookingInfo.datein)
			return itsOver
		},
		bookingEditable(){
			const checkout = moment(this.bookingInfo?.dateout).add(23, 'hours')
			const checkin = moment(this.bookingInfo?.datein)

			const current = moment().isBetween(checkin, checkout) && this.checkInItsOver && !this.checkOutItsOver
			const dateIn = moment(checkin).subtract(1, 'day')
			const before = moment(currentDate()).isSame(dateIn)

			return {current, before, valid: !current && !before  }
		},
	},
	methods: {
		...mapActions("mySales", ["getRooms"]),
		...mapActions("bookings", ["fetchBookings", "getInitialContentBooking"]),
		...mapActions("fivesClubCatalogs", ["fetchtOwnerBookingId", "getContractOwner", "fetchBenefitsByYearContract", "fetchOwners", "getOwnerBookingBlack"]),
		...mapMutations("fivesClubCatalogs", ["setRoomsDetail", "clearDataSearchForm", "setBookingInfo", "setBenefitYearList", "setAuthorization", "setRoomsDetailWeb", "setOwners", ]),
		...mapMutations("mySales", ["setRoomsOwner", "setDataSearchForm2", "setBookingSelected", ]),
		...mapActions("fivesClubContracts", ["fetchTransactions", "getDocumentsTypeForContracts", "getFilesByBooking", "deleteFileInOwnerBooking"]),
		showModalDocuments() {
			this.$root.$emit( "bv::show::modal", "modal-add-document" + this.bookingInfo.contractid )
		},
		stringBenefits( arr ){
			if (arr.length === 0) {
				return ''; // Si el array está vacío, devuelve una cadena vacía.
			}
			
			if (arr.length === 1) {
				return arr[0]
			}
			
			if(arr.length > 1){
				
				return arr.slice(0, -1).join('| ') + ' | ' + arr.slice(-1)
			}
		},
		async deleteDocument(payload) {
			this.isLoadingFiles = true;
			const { status, message } = await this.deleteFileInOwnerBooking({
				idBooking: this.bookingInfo.id,
				idDocumet: payload.id,
			});
			if (status) {
				this.$emit(
				"set-files",
				this.documents.filter((doc) => doc.id !== payload.id)
				);
				showAlertMessage("Ok: proceso finalizado", "InfoIcon", message, "success", 4000, "bottom-right")
			}
			this.isLoadingFiles = false;
		},
		async loadInfo() {

			this.clearDataSearchForm()
			this.booking = this.$route.params.booking

			if (this.booking != null) await this.setBookingFromUrl(this.booking)
			await this.getInitialContentBooking()

			await this.contractTable()
			await this.getInitialData()
		},

		async reload() {
			await this.contractTable()
			await this.getInitialData()
		},

		async setBookingFromUrl(cadena) {
			const arreglo = cadena.split("-")
			const id = parseInt(arreglo[1])
			const validEdit = arreglo[2]
			const orderIsANumber = !isNaN(id)
			if (orderIsANumber) {
				this.isLoadingBenefits = true
				this.idBooking = id
				let orderDetailBookings = await this.fetchtOwnerBookingId(id)
				const dateInYear = orderDetailBookings.length ? new Date( orderDetailBookings[0].datein ).getFullYear() : new Date().getFullYear()
				if(orderDetailBookings.length > 0) {
					orderDetailBookings = orderDetailBookings[0]
					orderDetailBookings.originalNights = orderDetailBookings.nights
					const contract = await this.getContractOwner({idHousing: orderDetailBookings.housing})
					this.actualcontract = contract[0]

					const item = contract.find((item) => item.statuscontractname === "FIRMADO")
					if (!item) {
						this.isLoadingBenefits = false
						return this.noValidContract = true
					}
					else this.noValidContract = false

					this.resortId = item.resortsid
					orderDetailBookings.resortId = this.resortId
					orderDetailBookings.contract = item.id
					orderDetailBookings.contractList = item
					orderDetailBookings.categoryroomName = item.categoryroomName

					orderDetailBookings = payloadBookigOwnerDingus(orderDetailBookings)
					orderDetailBookings.isEdit = validEdit

					const owner = await this.fetchOwners({id: orderDetailBookings.owner})
					const rawBenefits = await this.fetchBenefitsByYearContract({idContract: item.id, year: dateInYear,typeBenefit:'Noches' })

					this.setOwners(...owner)
					const payload = {
						orderDetailBookings: orderDetailBookings,
						benefit:rawBenefits,
					}
					this.setRoomsDetailWeb(payload)
					this.isLoadingBenefits = false
				}
			}
		},

		async sendInfo(info) {
			this.isLoadingBenefits = true
			this.showBookingsFilters = false
			this.showRooms = false
			this.clearDataSearchForm()
			this.owner = await this.fetchOwners({ id: info.id })
			if (this.owner?.length === 0) {
				this.isLoadingBenefits = false
				return showAlertMessage(
				"Error",
				"InfoIcon",
				`No se han encontrado los detalles del contrato`,
				"danger",
				4000,
				"bottom-right"
				)
			}
			let ownerInfo = toJson(...this.owner)
			const payloadHousing = {
				idHousing: info.numberHouse,
				idOwner: ownerInfo.id,
			}
			const contract = await this.getContractOwner(payloadHousing)
			if (contract?.length === 0) {
				this.isLoadingBenefits = false
				return showAlertMessage(
				"Error",
				"InfoIcon",
				`No se han encontrado los detalles del contrato`,
				"danger",
				4000,
				"bottom-right"
				)
			}
			const item = contract.find( (item) => item.statuscontractname === "FIRMADO" )
			this.resortId = item.resortsid
			const benefit = await this.fetchBenefitsByYearContract({idContract: item.id, year: info.year,typeBenefit:'Noches'})
			let orderDetailBookings = await this.fetchtOwnerBookingId(info.id) //no necesario, sol
			this.calculateDateContract(item.dateEnd)
			orderDetailBookings = orderDetailBookings
			ownerInfo.contract = item.id
			ownerInfo.contractList=item
			ownerInfo.adults = 1
			ownerInfo.numberChildren = 0
			ownerInfo.infoAdults = 0
			ownerInfo.ninos = []
			ownerInfo.nights = 1
			ownerInfo.resortId = this.resortId
			const payload = {
				orderDetailBookings: orderDetailBookings.length != 0 ? orderDetailBookings : ownerInfo,
				benefit,
				categoryroomName: item.categoryroomName,
				year: info.year,
			}

			this.setRoomsDetail(payload)
			this.showBookingsFilters = true
			this.isLoadingBenefits = false
			this.validBenefit=false
		},
		async searchRooms(rawData) {
			this.isLoadingRooms = true
			let data = toJson(rawData)

			if (this.owner == "") this.owner = await this.fetchOwners({ id: data.owner })

			let ownerInfo = this.owner
			let roomTypeCode = ""
			if (data.categoryroomName != null) roomTypeCode = data.categoryroomName
			else roomTypeCode = this.owners.categoryroomName || 0
			const hotelData = this.hotels.find((hotel) => hotel.id === data.resortId)
			//añado propiedades restantes
			data.adults = data.adults
			data.ageChildren = []
			data.children = data.ninos
			data.dateIn = data.dateIn
			data.dateOut = data.dateOut
			data.hotel = hotelData.dingusCode || null
			data.idHotel = data.resortId
			if (data.resortId === 3) data.hotel = "RAF"
			if (data.hotel === "RAF") data.resortId = 5
			data.idioma = "en"
			data.isManualRate = false
			show: false,
			data.language = "en"
			data.manualRate = ""
			data.isguest = false
			data.origin = 2
			data.promoCode = ""

			data.promoCodeManual = ""
			data.rate = ""
			data.rateplanid = ""
			data.roomCode = ""
			data.tarifa = ""
			const TA = data.benefits.find((item) => item.code === "TA")
			if (TA) data.highSeason = TA
			data.selectedBenefit = data.benefits.find( (item) => item.selected === true )

			data.vendorcode = "TFCOWNERS"
			data.owner = toJson(...ownerInfo)

            data.idContract = data.contract
			data.roomType = roomTypeCode
            // data.categoryroomId = data.categoryroomId
			data.isWeb = false

			this.setOwners(...ownerInfo)
			let selectedBenefits
			// const benefit = makeParamsForOwnersNigths(data.benefits)
			const Benefit = findSelectedBenefitsforOwnersNigths(data.benefits)
			data.promotionCode = "DROP 100"
			if (Benefit) {
				selectedBenefits = Benefit.benefit
			} else {
				showAlertMessage("Error", "InfoIcon", `Hubo un error al procesar el beneficio`, "danger", 4000, "bottom-right" )
				this.isLoadingRooms = false
			}

			//pedir noches disponibilidad de noches primero
			const rooms = await this.getRooms(data)

			if ( rooms.length === 0) {
				showAlertMessage("No tiene noches disponibles", "InfoIcon", "Parece que no tiene noches disponibles. Pruebe con otros filtros", "danger", 5000, "bottom-right")
				this.isLoadingRooms = false
				return
			}
			// fin de pedir noches

			//validacion de cutoff, blackout
			const payloadValid = {
				dateIn: data.dateIn,
				dateOut: data.dateOut,
				idBooking: data.id,
				idContract: data.contract,
				idOwner: data.owner.id, //id del owner, está en contrato
				isEdit: data.isEdit || false,
			}

			this.isLoadingRooms = true

			const valid = await this.getOwnerBookingBlack(payloadValid)

			if( valid.nights == 0 ){
				const newNights = this.bookingInfo.nights
				const originalNights = this.bookingInfo.originalNights
				const isMenorNewNights =  newNights <= originalNights

				if( !isMenorNewNights ){
					await this.nochesInsuficientes(valid)
					this.isLoadingRooms = false
					return
				}

			}

			if( valid.blackout == -1 ){
					this.$swal.fire({
					title: "",
					text: "La reservación correspondiente no tiene noches de temporada alta suficientes",
					showDenyButton: false,
					confirmButtonText: "ok",
					denyButtonText: "No",
				})
				return false
			}

			if ( valid.additional == true && data.contractList.membershipcode == 'CLASSIC') {
				this.isLoadingRooms = false
				await this.existeUnaReserva(valid)
				return false
			}

			if( valid?.booksNear ){
				this.isLoadingRooms = false
				const manyBooks = valid?.booksNear?.data?.length > 1
				await this.nearDatesModal({
					icon: 'AlertCircleIcon',
					title: manyBooks ? 'Se han encontrado varias reservas cercanas' : 'Una reserva cercana encontrada',
					message: `${ manyBooks ? 'Existen reservaciones' : 'Hay una reserva'} cerca de los dias seleccionados. Elija otras fechas con una diferencia de ${valid?.booksNear?.lapse} días a ${ manyBooks ? 'estas':'esta'}`,
					data: valid?.booksNear?.data,
					dates: {
						dateIn: data.dateIn,
						dateOut: data.dateOut,
					}
				})
				return
			}

			// si status o cutoff es true, require autorización
			if ( valid.status  || valid.cutoff) {


				const isConfirmed = await this.requestAuthorization(valid)

				this.validationsData.needAuthorization =  true,
				this.validationsData.messageAuth = 'La reserva requiere autorización para poder confirmarla'

				this.validationsData.hasCutoff =  valid.cutoff,
				this.validationsData.messageCutoff = valid.info ? `${valid.info?.name || '' }, días: ${valid.info?.total || '' } `: ''

				const payloadNames = {
					children: data.children,
					infoAdults: data.infoAdults,
				}
				this.setBookingSelected(null)

				this.isLoadingRooms = false

				if (!isConfirmed) {
					makeParamsOwners(payloadNames)
					this.validationsData = {
						needAuthorization: false,
						messageAuth: '',
						hasCutoff: false,
						messageCutoff: ''
					}
					return
				}
			}

			const payload = {
				authorization: valid.status,
				blackoutValid: valid.blackoutValid,
				additional: valid.additional,
				nightsValid: valid.nights,
				cutoff: valid.cutoff,
				nightsTA: valid.nightsTA,
				info: valid.info ? `${valid.info?.name || '' }, días: ${valid.info?.total || '' } `: '',
			}

			this.setAuthorization(payload)

			if(valid.status && valid.nights == 0){
				showAlertMessage("Error", "InfoIcon", `${valid.message} | Intente usar otros beneficios si tiene disponibles `, "danger", 5000, "bottom-right" )
			}

			data.blackout = valid.blackout

			this.setDataSearchForm2(data) //lo pongo en estado para ocuparlo como payload en peticion al resetear tarifas de un room

			this.setRoomsOwner(rooms)
			this.isLoadingRooms = false
			this.showRooms = true
		},
	
		
		goToHome() {
			if(this.ownersBookingData.status==1){
				this.tabIndex=0
			}else{
				this.tabIndex=0
			}

			this.$router.push({ name: "OwnersBooking", params: { index: this.tabIndex, bookingId: this.ownersBookingData.id } })
		},

		async nochesInsuficientes(valid) {
			const { isConfirmed } = await this.$swal.fire({
				title: "",
				text: "La reservación correspondiente no tiene noches suficientes",
				showDenyButton: false,
				confirmButtonText: "ok",
				denyButtonText: "No",
			})
			return isConfirmed
		},
		async existeUnaReserva(valid) {
			const { isConfirmed } = await this.$swal.fire({
				title: "",
				text: "Ya existe una reservacion dentro de estos dias.",
				showDenyButton: false,
				confirmButtonText: "ok",
				denyButtonText: "No",
			})
			return isConfirmed
		},
		async requestAuthorization(valid) {
			const { isConfirmed } = await this.$swal.fire({
				title: "No cumple los requisitos para realizar una reserva",
				text: "Quiere solicitar una autorización?",
				showDenyButton: true,
				confirmButtonText: "Sí",
				denyButtonText: "No",
				customClass: {
					actions: 'my-actions',
					confirmButton: 'mr-1 order-1',
					denyButton: 'order-2',
				}
			})
			return isConfirmed
		},
		async getInitialData() {
			this.documents = await this.getFilesByBooking({ idBooking:this.bookingInfo.id })
			this.fileTypes = await this.getDocumentsTypeForContracts()
		},
		async contractTable() {
			if (this.bookingInfo.isEdit != "true") return false

			this.isLoadingTableBenefit = true
			let itemfield = []
			const year = new Date().getFullYear()
			const payload = {
				id: this.bookingInfo.contractid,
				year,
			}
			const benefitperyear = await this.fetchBenefitsByYearContract({idContract: payload.id, year,})

			const transactionsData = await this.fetchTransactions(payload)
			benefitperyear.forEach((item) => {
			const transa = transactionsData.find( (contract) => contract.benefitId === item.benefit.benefit && contract.id == item.benefit.idTransa )
				if (item.benefit.includebooking != false)
				itemfield.push({
					loadingBenefit: false,
					benefit: item.benefit.benefit,
					benefitname: item.benefit.benefitname,
					benefitqty: item.benefit.benefitqty,
					amount: item.benefit.amount,
					balance: item.benefit.balance,
					avaiable: item.benefit.used,
					idTransaction: transa ? transa.id : "",
				})
			})
			this.itemFields = itemfield
			this.isLoadingTableBenefit = false
		},
		setDocuments(documents){
			this.documents  = documents
		},
		async searchBenefitsByYear(payload){
			this.isLoadingBenefits = true
			const benefit = await this.fetchBenefitsByYearContract({idContract: payload.id, year: payload.year,typeBenefit:'Noches'})
			this.$emit("set-benefitsByYear",benefit)
			if (benefit.length === 0) {
					this.validBenefit=true
				this.isLoadingBenefits = false
				return false

			}
			this.validBenefit=false
			const payloadBenefit = {
				benefit: benefit,
				year:payload.year,
			}
			this.setBenefitYearList(payloadBenefit)

			this.isLoadingBenefits = false
		},

		calculateDateContract(dateEnd){
			let  dateContract= new Date(dateEnd)
			dateContract=this.sumarDias(dateContract, 1)
			const yearContract= dateContract.getFullYear()
			this.dateContractEnd=  formatDateOnly(dateContract, 'es')

		},
		sumarDias(fecha, dias){
			fecha.setDate(fecha.getDate() + dias)
			return fecha
		},
		async nearDatesModal(info){
			const {icon, title, message, data, dates} = info
			const h = this.$createElement

			// const titleVNode = h('label', { domProps: { innerHTML: title } })

			const datafields = [
				{key: 'code', label: 'Folio reserva'},
				{key: 'datein', label: 'Check in'},
				{key: 'dateout', label: 'Check out'}
			]

			const messageVNode = h('div', { class: ['foobar'] }, [
				// h('feather-icon', { props: {icon} }),
				h('p', { class: ['text-center mb-1'] }, [
				`${message}`,
				h('br'),
				h('strong', [`Sus fechas: ${dates.dateIn} ➡️ ${dates.dateOut}`]),
				]),
				h('b-table', {
				props: {
					items: data,
					fields: datafields,
					small: true,
					responsive: true,
				}
				})
			])

			const modal = await this.$bvModal.msgBoxOk([messageVNode],{
				title,
				size: 'sm',
				centered: true,
				hideHeaderClose: false,
			})

			return modal
		},

	},
}
</script>

<style lang="scss">
.head-book{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.display-6 {
  font-size: 15px;
}
.border-head-collapse{
  border: 2px solid #333366;
}
.head-beneficios{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 2rem;
}
.btn-docs-up{
	display: flex;
	flex-direction: row-reverse;
}
.my-actions { margin: 0 auto; margin-top: 1rem; }
	.order-1 { order: 1; }
</style>

