<template>
  <div class="pt-1">
    <h4 class="mb-1">Datos de la Reserva</h4>
    <b-table
        :items="arrayItems"
        :fields="bookingFields"
        small
        responsive
        striped
    />
    <hr>
    <b-row>
        <b-col>
            <strong>Adultos: </strong>{{ bookingInfo.adults }}<br>
            <li v-for="adults in bookingInfo.infoAdults" :key="adults.key" class="m-1">
                <strong>Nombre: </strong>{{ adults.name }} {{ adults.lastname }}<br>
                <span v-if="adults.email"><strong>Email: </strong>{{ adults.email }}<br></span>
                <span v-if="adults.phone"><strong>Teléfono: </strong>{{ adults.phone }}<br></span>
            </li><br>

        </b-col>
        <b-col v-if="hasChildrens">
            <strong>Niños: </strong>{{ bookingInfo.children }}<br>
            <li v-for="ninos in bookingInfo.ninos" :key="ninos.key" class="m-1">
                <strong>Nombre: </strong>{{ ninos.name }} {{ ninos.lastname }}<br>
                <span v-if="ninos.phone"><strong>Edad: </strong>{{ ninos.edad }}<br></span>
            </li><br>
        </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
    created() {
        this.makeItemsArray()
    },
    props: {
        bookingInfo: {
            type: Object,
            required: true,
        },
    },
    data(){
        return {
            arrayItems: null,
            bookingFields: [
                {key: 'resortsname', label: 'Hotel'},
                {key: 'datein', label: 'Check In'},
                {key: 'dateout', label: 'Check Out'},
                {key: 'nights', label: 'Noches'},
                {key: 'occupantname', label: 'Tipo de ocupacion'},
                {key: 'internalnotes', label: 'Notas de Prearraival'}
            ]
        }
    },
    computed: {
        hasChildrens(){
            return this.bookingInfo?.children > 0
        }
    },
    methods: {
        makeItemsArray(){
            this.arrayItems = [{
                resortsname: this.bookingInfo?.contractList.resortsname,
                datein: this.bookingInfo?.datein,
                dateout: this.bookingInfo?.dateout,
                nights: this.bookingInfo?.nights,
                occupantname: this.bookingInfo?.occupantname,
                internalnotes: this.bookingInfo?.internalnotes || 'N/A'
            }]
        }
    }
}
</script>
