var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","id":'modal-add-document' + _vm.bookingInfo.contractid,"title":"Añadir un documento","ok-only":"","scrollable":"","hide-footer":""}},[_c('b-overlay',{staticClass:"p-2",attrs:{"show":_vm.isSavingFile,"rounded":""}},[_c('ValidationObserver',{ref:"uploadFilesForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveFile($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"tipo de archivo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Tipo de archivo"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.docFile.type),callback:function ($$v) {_vm.$set(_vm.docFile, "type", $$v)},expression:"docFile.type"}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione tipo")]),_vm._l((_vm.fileTypes),function(file){return _c('option',{key:file.id,domProps:{"value":file.id}},[_vm._v(" "+_vm._s(file.type)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"archivo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Archivo"}},[_c('b-form-file',{attrs:{"placeholder":"Adjunte un archivo...","drop-placeholder":"Drop file here...","state":errors[0] ? false : valid ? true : null,"browse-text":"🔎"},on:{"input":function($event){return _vm.setImgFile(_vm.docFile.file)}},model:{value:(_vm.docFile.file),callback:function ($$v) {_vm.$set(_vm.docFile, "file", $$v)},expression:"docFile.file"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0] ? "Se debe adjuntar un archivo" : ""))])],1)}}],null,true)}),(_vm.docFile.file)?_c('b-card-text',{staticClass:"my-1"},[_vm._v(" Archivo seleccionada: "),_c('strong',[_vm._v(_vm._s(_vm.docFile ? _vm.docFile.file.name : ""))])]):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"float-right"},[_c('b-button',{staticClass:"btn-block",attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.isSavingFile}},[(_vm.isSavingFile)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Subir archivo ")],1)],1)])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }