<template>
	<div style="height: inherit">
		
		<div class="text-center" v-if="isLoadingRooms">
			<b-spinner label="Loading..." variant="success"/><br>
			<strong>Buscando información</strong>
		</div>

		<div v-else-if="bookingSelected && showRooms">	
			<b-card no-body style="max-width: 100%;"  v-for="(room, index) in bookingSelected.rooms" :key="index">
				<Room :validationsData="validationsData" :room="room" :authorization="authorization" :authorizationList="authorizationList" @reload-order="getInitialData" /> <hr>
			</b-card>
		</div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Room from '@/modules/fivesClub/components/catalogs/ownersBookings/Room'
import { currentDate } from '@/helpers/helpers'

export default {
	props:{
		isLoadingRooms:{
			type: Boolean,
			required: true
		},
		showRooms:{
			type: Boolean,
			required: true
		},
		listBenefit:{
			type: Number,
			required: true,
		},
		validationsData:{
			type: Object,
			required: true
		}
	},
	components: {
		Room
	},
	computed:{
		...mapState("fivesClubCatalogs", ["authorization","authorizationList","bookingInfo"]),
		...mapState('mySales',['bookingSelected'])    ,
		checkOutItsOver(){
			let itsOver = false
			if(this.bookingInfo){
				const checkout = new Date(this.bookingInfo.dateout )
				const fechaActual = new Date(currentDate())
				itsOver = fechaActual > checkout
			}
			return itsOver
		},

	},  
	methods: {
		...mapMutations('mySales', ['setRateBooking']),
		setRate(booking, selectedRate){
			const { id  } = booking
			const payload = { id, selectedRate }
			if( selectedRate ) this.setRateBooking(payload)
		},
		getInitialData(){
			this.$emit('get-initial-data')
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
