<template>
  <div>
    <b-row>
		<div class="col-xs-6 col-md-3 col-sm-12">
			<ImgRoom :room="room" />
      	</div>
		<div class="col-xs-8 col-md-8 col-sm-12">
			<b-card-body>

				<div class="title-room">
					<h6 class="item-name"> <b class="text-body"> {{ room.Name }} </b> </h6>
					<b-badge :variant=" bookingInfo.categoryroomName == room.RoomTypeCode ? 'success':'warning' ">
						<feather-icon icon="AlertCircleIcon" class="mr-25" />
						<span> Habitación: {{ bookingInfo.categoryroomName }}</span>
					</b-badge>
				</div>
								
				<Rates v-if="!isLoadingRates" :room="roomInfo" />

				<div class="text-center alerts-p">
					<p class="text-danger" v-if="validationsData.needAuthorization">{{ validationsData.messageAuth }}</p>
					<p class="text-warning" v-if="validationsData.hasCutoff">{{ validationsData.messageCutoff }}</p>
				</div>

				<AddBookingCart :room="room" @refresh-order="fetchInitialData"/>

			</b-card-body>
		</div>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ImgRoom from "@/modules/shop/components/bookings/ImgRoom";
import Rates from "@/modules/shop/components/bookings/OwnersRates";
import AdditionalDataRoom from "@/modules/shop/components/bookings/AdditionalDataRoom";
import AddBookingCart from "@/modules/fivesClub/components/catalogs/ownersBookings/AddBookingCart";

export default {
	props: {
		room: {
			type: Object,
			required: true,
		},
			authorization: {
			type: Boolean,
		},
			authorizationList: {
			type: Object,
		},
		validationsData:{
			type: Object,
			required: true
		}
  },
  components: {
    ImgRoom,
    Rates,
    AdditionalDataRoom,
    AddBookingCart,
  },
  data() {
    return {
      isLoadingRates: false,
    };
  },
  computed: {
    ...mapState("fivesClubCatalogs", ["bookingInfo"]),
    benefit() {
      return this.bookingInfo.benefits.find( (benefits) => benefits.selected === true)
    },
    nights() {
      return this.bookingInfo.nights
    },
    roomInfo() {
      this.room.rates = this.room.rates.filter((rooms) => rooms.MealPlanCodes === 'EPSOC')
      this.room.detailSelected = this.room.rates.length ? this.room.rates[0] : [] // detail selected obtiene el
      return this.room
    },
  },
  methods: {
    setIsLoadingRates(boolean) {
      this.isLoadingRates = boolean
    },
    fetchInitialData(){
      this.$emit('reload-order')
    }
  },
};
</script>

<style scoped>
.title-room{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.5rem;
}
.alerts-p p{
	margin: 0;
	margin-bottom: .5rem;
	font-size: 1.1rem;
	font-weight: 600;
}
</style>
